import * as React from "react";
import Link from "gatsby-link";

const NotFoundPage = () => (
  <div>
    <h1>Not Found</h1>
    <p>
      We took a wrong turn, let's head{" "}
      <Link
        to="/"
        style={{
          color: "blue",
          textDecoration: "none",
        }}
      >
        back
      </Link>
      ?
    </p>
  </div>
);

export default NotFoundPage;
